import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { AuthService } from './services/auth.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Utenti', url: 'users', icon: 'paper-plane' },
    { title: 'Eventi', url: 'events', icon: 'paper-plane' },
    { title: 'Luoghi (Itinerari, Musei, ecc.)', url: 'places', icon: 'paper-plane' },
    // { title: 'Attività', url: 'activities', icon: 'paper-plane' },
    // { title: 'Archived', url: '/folder/archived', icon: 'archive' },
    // { title: 'Trash', url: '/folder/trash', icon: 'trash' },
    // { title: 'Spam', url: '/folder/spam', icon: 'warning' },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  loggedUser: any;
  user:any;

  constructor(private authService: AuthService) {

  }

  ngOnInit (){
    this.loggedUser = this.authService.getLoggedUser();
    this.user=this.loggedUser?.email;
  }

  async logout() {
    if (!!(await this.authService.logout())) {
      window.location.assign('/');
    }
  }
}
