import { CookiesService } from './cookies.service';
import { Injectable, Type } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from './auth.service';

export enum TypeHttp {
  getMethod = 1,
  postMethod = 2,
  deleteMethod = 3,
  createMethod = 4
}

@Injectable({
  providedIn: 'root'
})
export class ServicesInterfaceService {

  constructor(
    private httpClient: HttpClient,
    private cookiesService: CookiesService
  ) {

  }

  async execute(typecall: TypeHttp, url: string, params: any = {}, mapCallback: CallableFunction = (item: any) => item) {
    let result: any | [{}];

    try {
      switch (typecall) {
        case TypeHttp.getMethod:
          result = await this.httpGet(url, params);
          break;
        case TypeHttp.postMethod:
          result = await this.httpPost(url, params);
          break;
        case TypeHttp.deleteMethod:
          result = await this.httpDelete(url, params);
          break;
        default:
          break;
      }

      if (Array.isArray(result)) {
        return result.map((item: any) => mapCallback(item))
      }
      return mapCallback(result);
    } catch (error) { //TODO
    }
  }


  async httpGet(endPoint: any, params: any = {}): Promise<any | boolean> {
    return new Promise(async (resolve) => {
      const token = await this.getAccessToken();
      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }),
        params: params
      };

      const requestUrl = `${environment.apiBaseUrl}${endPoint}`;
      this.httpClient.get(requestUrl, httpOptions)
        .subscribe({
          next: (response: any) => {
            if (!!response.error) {
              console.log('then() error', response);
            }
            return resolve(response);
          },
          error: (error: any) => {
            console.log(error);
            return resolve(null);
          },
          // complete: () => { }
        });
    });
  }

  async httpPost(url: any, params: any = {}): Promise<any> {
    return new Promise(async (resolve) => {
      const token = await this.getAccessToken();
      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
        }),
      };
      const requestUrl = `${environment.apiBaseUrl}${url}`;
      this.httpClient.post(requestUrl, params, httpOptions)
        .subscribe({
          next: (response: any) => {
            if (!!response.error) {
              console.log('then() error', response);
            }
            return resolve(response);
          },
          error: (error: any) => {
            console.log(error);
            return resolve(null);
          },
          complete: () => { }
        });
    });
  }

  async httpDelete(url: string, params: any = {}): Promise<any> {
    return new Promise(async (resolve) => {
      const token = await this.getAccessToken();
      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }),
        body: JSON.stringify(params)
      };
      const requestUrl = `${environment.apiBaseUrl}${url}`;
      this.httpClient.delete(requestUrl, httpOptions)
        .subscribe({
          next: (response: any) => {
            if (!!response?.error) {
              console.log('then() error', response);
            }
            return resolve(response);
          },
          error: (error: any) => {
            console.log(error);
            return resolve(null);
          },
          complete: () => { }
        });
    });
  }

  async getAccessToken() {
    const token: string | null = this.cookiesService.get('token');
    return token;
  }
}
