import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  constructor() { }

  set(key: string, value: string , daysToLive: number | null = null, secure: boolean = false, path: string | null = null, domain: string | null = null) {
    // Encode value in order to escape semicolons, commas, and whitespace
    let cookie = `${key}=${encodeURIComponent(value)}`;

    if (!!daysToLive) {
        cookie += `; max-age=${(daysToLive*24*60*60)}`;
    }

    if (!!path) {
      cookie += `; path=${path}`;
    }

    if (!!domain) {
      cookie += `; domain=${domain}`;
    }

    if (secure) {
      cookie += '; secure';
    }

    document.cookie = cookie;
  }

  get(key: string) {
    // Split cookie string and get all individual name=value pairs in an array
    const cookieArr = document.cookie.split(';');

    for (const cookie of cookieArr) {
      const cookiePair = cookie.split('=');
      if (key === cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1]);
      }
    }

    // Return null if not found
    return null;
  }

  delete(key: string, path: string | null = null) {
    let cookie = `${key}=`;

    if (!!path) {
      cookie += `; path=${path}`;
    }

    cookie += `; expires=Thu, 01 Jan 1970 00:00:01 GMT`;

    document.cookie = `${cookie};`;
  }

}
