import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('./pages/users/users.module').then( m => m.UsersPagePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'users/:id',
    loadChildren: () => import('./pages/users/users.module').then( m => m.UsersPagePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('./pages/user/user.module').then( m => m.UserPagePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'user/:id',
    loadChildren: () => import('./pages/user/user.module').then( m => m.UserPagePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'events',
    loadChildren: () => import('./pages/events/events.module').then( m => m.EventsPagePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'events/:id',
    loadChildren: () => import('./pages/events/events.module').then( m => m.EventsPagePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'event',
    loadChildren: () => import('./pages/event/event.module').then( m => m.EventPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'event/:id',
    loadChildren: () => import('./pages/event/event.module').then( m => m.EventPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'places',
    loadChildren: () => import('./pages/places/places.module').then( m => m.PlacesPagePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'place/:id',
    loadChildren: () => import('./pages/place/place.module').then( m => m.PlacePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'place',
    loadChildren: () => import('./pages/place/place.module').then( m => m.PlacePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'place/:id',
    loadChildren: () => import('./pages/place/place.module').then( m => m.PlacePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'activity',
    loadChildren: () => import('./pages/activity/activity.module').then( m => m.ActivityPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'activity/:id',
    loadChildren: () => import('./pages/activity/activity.module').then( m => m.ActivityPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'activity',
    loadChildren: () => import('./pages/activity/activity.module').then( m => m.ActivityPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'activity/:id',
    loadChildren: () => import('./pages/activity/activity.module').then( m => m.ActivityPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'activities',
    loadChildren: () => import('./pages/activities/activities.module').then( m => m.ActivitiesPageModule),
    canLoad: [AuthGuard]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
