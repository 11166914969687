import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {

  constructor(
    private router: Router,
    private authService: AuthService
  ) {

  }

  async canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {

      const token: string | null  = this.authService.getToken();
      const loggedUser: any = this.authService.getLoggedUser();

      if (!!token === false || loggedUser.role !== 'admin') {
        this.router.navigate(['/login']);
        return resolve(false);
      }

      return resolve(true);

    });
  }
}
