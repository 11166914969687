import { ServicesInterfaceService } from './services-interface.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CookiesService } from './cookies.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService  {

  loggedUser: BehaviorSubject<any|null> = new BehaviorSubject<any|null>(null);

  constructor(
    private cookiesService: CookiesService,
    private apiService: ServicesInterfaceService
  ) {
    this.init();
  }

  async init() {
    if (!!this.getLoggedUser()) { return; }
    const token = this.getToken();
    if (!!token) {
      await this.me(token);
    }
  }

  async login(credentials: any): Promise<any|null> {
    try {
      // await this.maintenanceService.clearCache();
      const response = await this.apiService.httpPost('/auth/login', credentials);

      // Save user data in local storage
      if (!!response.error === false) {
        this.setLoggedUser(response.data.user);
        this.setToken(response.data.tokens.access_token);
      }
      return response;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async logout() {
    try {
      const token = this.getToken();
      await this.apiService.httpPost('/auth/logout', {});
      this.deleteLoggedUser();
      this.deleteToken();
      return true;
    } catch (e) {
      console.log(e);
      return e;
    }
  }

  async me(token: string) {
    try {
      const response = await this.apiService.httpGet('/user/me', {});

      // Save user data in local storage
      if (!!response) {
        this.setLoggedUser(response);
      }

      return response;
    } catch (e) {
      console.log(e);
      return e;
    }
  }

  setLoggedUser(loggedUser: any) {
    if (!!loggedUser === false) {
      loggedUser = '';
    }
    loggedUser.role='admin'; //TODO: forzatura
    localStorage.setItem('loggedUser', JSON.stringify(loggedUser));
    this.loggedUser.next(loggedUser);
  }

  getLoggedUser() {
    let loggedUser = this.loggedUser.getValue();

    if (!!loggedUser === false) {
      loggedUser = localStorage.getItem('loggedUser');
      if (!!loggedUser) {
        loggedUser = JSON.parse(loggedUser);
      } else {
        loggedUser = null;
      }

      this.loggedUser.next(loggedUser);
    }

    return loggedUser;
  }

  deleteLoggedUser() {
    localStorage.removeItem('loggedUser');
    this.loggedUser.next(null);
  }

  setToken(token: string) {
    if (!!token === false) { token = ''; }
    this.cookiesService.set('token', token, 1);
  }

  getToken() {
    return this.cookiesService.get('token');
  }

  deleteToken() {
    this.cookiesService.delete('token');
  }
}
